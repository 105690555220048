import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "components/Layout";
import { Reveal } from "components/Reveal";
import { HomeBillboard } from "components/HomeBillboard";
import { HomeAnnouncements } from "components/HomeAnnouncements";
import { HomeCharacters } from "components/HomeCharacters";
import { HomeNews } from "components/HomeNews";
import { HomeGameFeatures } from "components/HomeGameFeatures";
import { HomeTrailer } from "components/HomeTrailer";

export default function IndexPage({ data }: PageProps<Queries.HomeQuery>) {
    return (
        <Layout hideLogo>
            <HomeBillboard />
            <Reveal>
                <HomeTrailer trailer={data.trailer} />
            </Reveal>
            <Reveal>
                <HomeGameFeatures features={data.features} />
            </Reveal>
            <Reveal>
                <HomeCharacters characters={data.characters} />
            </Reveal>
            <Reveal>
                <HomeNews news={data.news} />
            </Reveal>
            <Reveal>
                <HomeAnnouncements />
            </Reveal>
        </Layout>
    );
}

export const query = graphql`
    fragment Translation on Locale {
        ns
        data
        language
    }
    query Home($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ...Translation
                }
            }
        }
        trailer: allMarkdownRemark(
            filter: {
                frontmatter: { type: { eq: "trailer" }, publishOnOfficial: { eq: true } }
                fields: { language: { eq: $language } }
            }
            limit: 9
            sort: { fields: [frontmatter___published], order: DESC }
        ) {
            edges{
                node {
                    id
                    frontmatter {
                        link
                        icon {
                            publicURL
                        }
                    }
                }
            }
        }
        features: allMarkdownRemark(
            filter: {
                frontmatter: { type: { eq: "feature" } }
                fields: { language: { eq: $language } }
            }
            sort: { fields: [frontmatter___order], order: ASC }
        ) {
            edges {
                node {
                    id
                    html
                    frontmatter {
                        title
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    sizes: "(max-width: 768px) 100vw, 748px"
                                )
                            }
                        }
                    }
                }
            }
        }
        characters: allMarkdownRemark(
            filter: {
                frontmatter: { type: { eq: "character" } }
                fields: { language: { eq: $language } }
            }
            sort: { fields: frontmatter___order }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        cv
                        message
                        area
                        slug
                        swiperImage {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: TRACED_SVG
                                    quality: 90
                                    sizes: "(max-width: 768px) 100vw, 571px"
                                )
                            }
                        }
                    }
                }
            }
        }
        news: allMarkdownRemark(
            filter: {
                frontmatter: { type: { eq: "news" }, outdated: { ne: true } }
                fields: { language: { eq: $language } }
            }
            sort: { fields: frontmatter___published, order: DESC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        url
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    sizes: "1230px"
                                )
                            }
                        }
                        thumbnailMobile {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;

export { Head } from "components/GCSRewriteURL";
